import { BrowserRouter, Route, Routes } from "react-router-dom";

import Main from './Main';
import View from './View';

function Router(){
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/view/:number" element={<Main></Main>} /> 
                <Route path="/" element={<Main></Main>} />    
            </Routes>
        </BrowserRouter>
    );
}
export default Router;