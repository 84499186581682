import styled from "styled-components";

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
`;

const Banner = styled.div`
    height: 150px;
    width: 100%;
    background-color: wheat;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    font-weight: 600;
`;

const Contents = styled.div`
    height: 750px;
    width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 600;
    color: white;
    line-height: 55px;
    position: relative;

`;

const Line = styled.div`
    border-bottom: 2px solid #BEBEBE;
    width: 100%;
`;

const Created = styled.span`
    position: absolute;
    top: 20px;
    right: 20px;
    color: #BEBEBE;
    line-height: 30px;
    font-size: 15px;
`;

function Main(){
    return (
        <Container>
            <Banner>
                모이라랩 홈페이지
            </Banner>
            <br/><br/>
            <Contents>
                <Created>(게시일) 2022-11-10 15:23</Created>
                해산 및 채권신고 공고 (2차)<br/><br/>

                당 회사는 2021년 9월 10일 주주 전원의 서면결의로 해산하기로 결의하였으므로 당 회사에 대하여 채권이 있는 채권자는 이 공고일로부터 2개월 이내에 이의 및 그 채권액을 신고하여 주시기 바라며 만약 위 기간 내에 신고가 없는 채권자는 청산에서 제외됨을 알립니다.<br/><br/>


                2022년 11월 10일<br/><br/>
                

                모이라랩 주식회사<br/>
                서울 동대문구 망우로14가길 41-22(휘경동)<br/>
                청산인 이승도
            </Contents>
            <br/><br/><br/><br/>
            <Line />
            <br/><br/><br/><br/>
            <Contents>
                <Created>(게시일) 2022-09-06 10:23</Created>
                해산 및 채권신고 공고 (1차)<br/><br/>

                당 회사는 2021년 9월 10일 주주 전원의 서면결의로 해산하기로 결의하였으므로 당 회사에 대하여 채권이 있는 채권자는 이 공고일로부터 2개월 이내에 이의 및 그 채권액을 신고하여 주시기 바라며 만약 위 기간 내에 신고가 없는 채권자는 청산에서 제외됨을 알립니다.<br/><br/>


                2022년 9월 6일<br/><br/>
                

                모이라랩 주식회사<br/>
                서울 동대문구 망우로14가길 41-22(휘경동)<br/>
                청산인 이승도
            </Contents>
        </Container>
    );
}

export default Main;